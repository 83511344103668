//
//
//
//
//
//
export default {
  name: 'Doc',
  methods: {
    click: function click() {
      window.open('https://el-admin.vip/guide/', '_blank');
    }
  }
};